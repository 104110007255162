anchorContact = document.querySelector('.anchor-contact');

if (anchorContact) {
    anchorContact.addEventListener('click', function (e) {
        let offsetPosition = document.querySelector('#anchor_contact').offsetTop - 65;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });
    });
}
